import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TermsText, Wrapper } from "src/components/termsAndUse/styled";
import { H1, H2 } from "src/styles/components";
import Layout from "src/components/layout/layout";
import { HelmetComponent } from "components/helmet";

export const TermsAndUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout key="terms">
      <HelmetComponent />

      <Wrapper>
        <H1 transform="uppercase">Terms of Service</H1>
        <section>
          <TermsText>
            These Terms of Service govern your use of the website located at
            <a href="https://greyapes.com"> https://greyapes.com</a> and any
            related services provided by Greyapes.com.
          </TermsText>
          <TermsText>
            By accessing{" "}
            <a href="https://greyapes.com"> https://greyapes.com</a>, you agree
            to abide by these Terms of Service and to comply with all applicable
            laws and regulations. If you do not agree with these Terms of
            Service, you are prohibited from using or accessing this website or
            using any other services provided by Greyapes.com.
          </TermsText>
          <TermsText>
            We, Greyapes.com, reserve the right to review and amend any of these
            Terms of Service at our sole discretion. Upon doing so, we will
            update this page. Any changes to these Terms of Service will take
            effect immediately from the date of publication.
          </TermsText>
          <TermsText>
            These Terms of Service were last updated on 26 April 2022.
          </TermsText>
        </section>

        <StaticImage
          src="../../assets/images/term-and-use-1.png"
          alt="term-and-use-1"
        />

        <section>
          <H2 transform="uppercase">Limitations of Use</H2>
          <TermsText>
            By using this website, you warrant on behalf of yourself, your
            users, and other parties you represent that you will not:
          </TermsText>
          <ol>
            <TermsText as="li">
              modify, copy, prepare derivative works of, decompile, or reverse
              engineer any materials and software contained on this website;
            </TermsText>
            <TermsText as="li">
              remove any copyright or other proprietary notations from any
              materials and software on this website;
            </TermsText>
            <TermsText as="li">
              transfer the materials to another person or “mirror” the materials
              on any other server;
            </TermsText>
            <TermsText as="li">
              knowingly or negligently use this website or any of its associated
              services in a way that abuses or disrupts our networks or any
              other service Greyapes.com provides;
            </TermsText>
            <TermsText as="li">
              use this website or its associated services to transmit or publish
              any harassing, indecent, obscene, fraudulent, or unlawful
              material;
            </TermsText>
            <TermsText as="li">
              use this website or its associated services in violation of any
              applicable laws or regulations;
            </TermsText>
            <TermsText as="li">
              use this website in conjunction with sending unauthorized
              advertising or spam;
            </TermsText>
            <TermsText as="li">
              harvest, collect, or gather user data without the user’s consent;
              or
            </TermsText>
            <TermsText as="li">
              use this website or its associated services in such a way that may
              infringe the privacy, intellectual property rights, or other
              rights of third parties.
            </TermsText>
          </ol>
        </section>

        <section>
          <H2 transform="uppercase">Intellectual Property</H2>
          <TermsText>
            The intellectual property in the materials contained in this website
            are owned by or licensed to Greyapes.com and are protected by
            applicable copyright and trademark law. We grant our users
            permission to download one copy of the materials for personal,
            non-commercial transitory use.
          </TermsText>
          <TermsText>
            This constitutes the grant of a license, not a transfer of title.
            This license shall automatically terminate if you violate any of
            these restrictions or the Terms of Service, and may be terminated by
            Greyapes.com at any time.
          </TermsText>
        </section>
        <StaticImage
          src="../../assets/images/term-and-use-2.png"
          alt="term-and-use-2"
        />
        <section>
          <H2 transform="uppercase">Liability</H2>
          <TermsText>
            Our website and the materials on our website are provided on an 'as
            is' basis. To the extent permitted by law, Greyapes.com makes no
            warranties, expressed or implied, and hereby disclaims and negates
            all other warranties including, without limitation, implied
            warranties or conditions of merchantability, fitness for a
            particular purpose, or non-infringement of intellectual property, or
            other violation of rights.
          </TermsText>
          <TermsText>
            In no event shall Greyapes.com or its suppliers be liable for any
            consequential loss suffered or incurred by you or any third party
            arising from the use or inability to use this website or the
            materials on this website, even if Greyapes.com or an authorized
            representative has been notified, orally or in writing, of the
            possibility of such damage.
          </TermsText>
          <TermsText>
            In the context of this agreement, &ldquo;consequential loss&rdquo;
            includes any consequential loss, indirect loss, real or anticipated
            loss of profit, loss of benefit, loss of revenue, loss of business,
            loss of goodwill, loss of opportunity, loss of savings, loss of
            reputation, loss of use and/or loss or corruption of data, whether
            under statute, contract, equity, tort (including negligence),
            indemnity, or otherwise.
          </TermsText>
          <TermsText>
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Accuracy of Materials</H2>
          <TermsText>
            The materials appearing on our website are not comprehensive and are
            for general information purposes only. Greyapes.com does not warrant
            or make any representations concerning the accuracy, likely results,
            or reliability of the use of the materials on this website, or
            otherwise relating to such materials or on any resources linked to
            this website.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Links</H2>
          <TermsText>
            Greyapes.com has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement, approval, or
            control by Greyapes.com of the site. Use of any such linked site is
            at your own risk and we strongly advise you make your own
            investigations with respect to the suitability of those sites.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Right to Terminate</H2>
          <TermsText>
            We may suspend or terminate your right to use our website and
            terminate these Terms of Service immediately upon written notice to
            you for any breach of these Terms of Service.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Severance</H2>
          <TermsText>
            Any term of these Terms of Service which is wholly or partially void
            or unenforceable is severed to the extent that it is void or
            unenforceable. The validity of the remainder of these Terms of
            Service is not affected.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Governing Law</H2>
          <TermsText>
            These Terms of Service are governed by and construed in accordance
            with the laws of Cyprus. You irrevocably submit to the exclusive
            jurisdiction of the courts in that State or location.
          </TermsText>
        </section>
      </Wrapper>
    </Layout>
  );
};
